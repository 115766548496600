import React from 'react'
import '../../../css/contentMarketing.css'
import content from '../../../assets/images/contentmarketing.png'
import { BiCheck } from "react-icons/bi";
import ClientLogohead from '../../Home/ClientLogohead';
import CW from '../../../assets/images/contentmarketing1.png'
import CC from '../../../assets/images/content-creation.png'
import { FaRegArrowAltCircleUp } from "react-icons/fa";
import SEO from '../../../assets/images/SEO.png'
import contentDis from '../../../assets/images/content_fill.png'
import business from '../../../assets/images/businesspeople.png'

export const ContentMarketing = () => {
  return (
    <div>
      <div className='content_bg'>
    <div className="content_container">
      <div className="content_text_column">
      <h1 className='content_title'>Crafting Stories Building Brands, Driving Results</h1>
      <p className='brand_para'>Compelling content is the cornerstone of a successful marketing strategy in today’s digital landscape. At RIO, we help brands tell their story and connect with their audience in the most meaningful way. We specialize in creating and delivering top-notch content that drives engagement, builds brand loyalty, and boosts conversions.</p>
      <button className='content_button'>Let's connect <BiCheck className='content_icon1'/>

</button>
      </div>
    
<img src={content} alt="" />
    </div>
    
    </div>
    <ClientLogohead/>
    <div className='container'>
      <hr/>
    </div>
    
      <h2 className='content_title1'>Why Invest in Quality Content?</h2>
      <p className='content_tx_description'>In the digital age, content marketing is not just a trend—it's a vital component of any successful business strategy. In other words, it is an indispensable tool for any business looking to thrive in the digital world. By investing in a robust content strategy, you can build brand awareness, engage your audience, drive traffic, generate leads, and ultimately, grow your business.</p>
    
    <section className='content_wapp'>
            <div className="black content-grid">
           <img src={business} alt="" /> 
          <h4 className='content-grid-title'>Builds Brand Awareness</h4>
          <p className='content-grid-description'>Content marketing helps you reach a wider audience by showcasing your expertise and sharing valuable information. Regularly publishing high-quality content positions your brand as an industry leader and keeps you top of mind with potential customers.</p>
          </div>
          <div className="black content-grid">
          {/* <FontAwesomeIcon icon={faUncharted}  className='font_icon' /> */}
          
          <h4 className='content-grid-title'>Engages Your Audience</h4>
          <p  className='content-grid-description'>Engaging content captures the interest of your audience, encouraging them to interact with your brand. Whether through blog posts, social media, or videos, compelling content fosters a community around your brand, leading to stronger customer relationships.</p>
          </div>

            </section>
            <section className='content_wapp'>
            <div className="black staff-grid">
          {/* <img src={ideaicon} alt="" /> */}
          <h2>Comprehensive Solution</h2>
          <p>Odoo ERP provides an all-in-one platform that consolidates all your business functions into a single, easy-to-use interface. Manage your operations seamlessly with applications tailored to every aspect of your business.</p>
          </div>
          <div className="black staff-grid">
          {/* <FontAwesomeIcon icon={faUncharted}  className='font_icon' /> */}
          
          <h2>Scalable & Customizable</h2>
          <p>Whether you’re a small startup or a large enterprise, Odoo grows with your business. Customize the platform with over 30 main applications and 16,000+ third-party apps available in the Odoo App Store.</p>
          </div>

            </section>
            <section className='content_wapp'>
            <div className="black staff-grid">
          {/* <img src={ideaicon} alt="" /> */}
          <h2>Comprehensive Solution</h2>
          <p>Odoo ERP provides an all-in-one platform that consolidates all your business functions into a single, easy-to-use interface. Manage your operations seamlessly with applications tailored to every aspect of your business.</p>
          </div>
          <div className="black staff-grid">
          {/* <FontAwesomeIcon icon={faUncharted}  className='font_icon' /> */}
          
          <h2>Scalable & Customizable</h2>
          <p>Whether you’re a small startup or a large enterprise, Odoo grows with your business. Customize the platform with over 30 main applications and 16,000+ third-party apps available in the Odoo App Store.</p>
          </div>

            </section>
            <section className='content_wapp'>
            <div className="black staff-grid">
          {/* <img src={ideaicon} alt="" /> */}
          <h2>Comprehensive Solution</h2>
          <p>Odoo ERP provides an all-in-one platform that consolidates all your business functions into a single, easy-to-use interface. Manage your operations seamlessly with applications tailored to every aspect of your business.</p>
          </div>
          <div className="black staff-grid">
          {/* <FontAwesomeIcon icon={faUncharted}  className='font_icon' /> */}
          
            <h2>Scalable & Customizable</h2>
             <p>Whether you’re a small startup or a large enterprise, Odoo grows with your business. Customize the platform with over 30 main applications and 16,000+ third-party apps available in the Odoo App Store.</p>
          </div>

            </section>
            <h2>Services We Offer</h2>
            <div className="content_container">
              <img src={CW}  alt=''/>
              <div className="content_text_column">
              <h3 >Content Strategy Development</h3>
              <p>We'll work with you to develop a comprehensive content strategy that aligns with your business goals and audience needs. From content audits to editorial calendars, we cover all aspects to set you up for success.</p>
              
              <div className="digital-progress"> 
            <div className="progress-bar">

            </div>
            <h4>Client<br/>Satisfied</h4>
                     <div className="progress-bar1"></div>
                       <h4>Grow
                       Your Business</h4>
                        </div>
                           
                      </div>
                  </div>
                  <div className="content_container">
                        <div className="content_text_column">
                          <h3>Content Creation</h3>
                          <p>Our team creates a wide range of content types, including</p>
                          <FaRegArrowAltCircleUp className='brand_icon'/>
       <div className="brand_cloumn">
      
      <h3 className='brand_icon_title'>Blog Posts and Articles</h3>
      <p className='brand_icon_para'>Informative and engaging pieces that establish

your brand as an authority in your industry.</p>
       </div>

       <FaRegArrowAltCircleUp className='brand_icon'/>
       <div className="brand_cloumn">
      
      <h3 className='brand_icon_title'>Social Media Content</h3>
      <p className='brand_icon_para'>Eye-catching posts that drive engagement and

grow your online presence.</p>
       </div>

       <FaRegArrowAltCircleUp className='brand_icon'/>
       <div className="brand_cloumn">
      
      <h3 className='brand_icon_title'>Email Campaigns</h3>
      <p className='brand_icon_para'>Compelling newsletters and promotional

emails that nurture leads and boost sales.</p>
       </div>
       <FaRegArrowAltCircleUp className='brand_icon'/>
       <div className="brand_cloumn">
      
      <h3 className='brand_icon_title'>Video Scripts and Production</h3>
      <p className='brand_icon_para'>High-quality videos that tell your story

and captivate your audience.</p>
       </div>
      
                          </div>
                          <img src={CC} alt=''/>
                          </div>  
                    <div className='content_container'>
                      <img src={SEO} alt="" />
                      <div className="content_text_column">
                        <h3>SEO & Content Optimization</h3>
                        <p>Ensure your content gets found. We integrate SEO best practices into all our content, helping you rank higher in search results and attract organic traffic.</p>
                        <div className="digital-progress"> 
          
                     <div className="progress-bar1"></div>
                       <h4>Grow
                       Your Business</h4>
                        </div>
                      </div>

                    </div>
                    <div className='content_container'>
                    <div className="content_text_column">
                      <h3>Content Distribution</h3>
                      <p>We don't just create content; we make sure it reaches the right people. Our distribution services leverage multiple channels, including social media, email, and paid advertising, to amplify your message.</p>

                    </div>
                    <img src={contentDis} alt=''/>
                      </div> 
                      
      <div className='content_container'>
      <img src={contentDis} alt=''/>
      <div className="content_text_column">
                      <h3>Content Distribution</h3>
                      <p>We don't just create content; we make sure it reaches the right people. Our distribution services leverage multiple channels, including social media, email, and paid advertising, to amplify your message.</p>

                    </div>
      </div>
    </div>
  )
}
