import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css';

import Header from './components/Navbar';
import Home from './components/pages/Home';
import Footerbottom from './components/Footerbottom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import { Services } from './pages/Services';
// import { Blogs } from './pages/Blogs';
import { Blogs, MainServices } from './components/pages';
import Contact from './components/pages/Contact';
import Staffing from './components/Services/Staffing';
import Digitalmarketing from './components/Services/Digitalmarketing';
import Ipr from './components/Services/Ipr';
import Odoo from './components/Services/Odoo';

import Branding from './components/sub_pages/DigitalMarketing/Branding'
// import {MainServices} from './components/pages/MainServices'
import AppDevelopment from './components/Services/AppDevelopment';
import { ContentMarketing } from './components/sub_pages/DigitalMarketing/ContentMarketing';
// import WebDevelopment from './components/Services/WebDevelopment';

function App() {
  return (
  <Router>
<div className="App">
 <Header />
  <Routes>
    <Route path='/' element={<Home />} />
    <Route path='/service' element={<MainServices/>} />
    <Route path='/blog' element={<Blogs/>}/>
    <Route path='/contact' element={<Contact/>}/>
    <Route path='/our-service/staffing' element={<Staffing/>}/>
    <Route path='/our-service/digital-marketing/' element={<Digitalmarketing/>}/>
    <Route path='/our-service/app-development' element={<AppDevelopment/>}/>
    {/* <Route path='/our-service/web-development' element={<WebDevelopment/>}/> */}
    <Route path='/our-service/ipr/' element={<Ipr/>}/>
    <Route path='/our-service/odoo/' element={<Odoo/>}/>
     <Route path ='/our-service/digital-marketing/branding' element={<Branding/>}/>
     <Route path ='/our-service/digital-marketing/content-marketing' element={<ContentMarketing/>}/>
  </Routes> 
    <Footerbottom/>    
    </div>
    </Router>

   
  );
}

export default App;
