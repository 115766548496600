import React from 'react'
import AppBanner from '../../assets/images/App-development.png'
import ios from '../../assets/images/iOS-Android-App.jpg'
import consultion from '../../assets/images/App-Consulting-Prototyping-1.jpg'
import uiux from '../../assets/images/App-UI-UX-1.jpg'
import support from '../../assets/images/Apps-supports-1.jpg'
import ios2 from '../../assets/images/iOS-Android-App-2.jpg'
import AppBanner2 from '../../assets/images/App-bg-img.webp'
import flutter from '../../assets/images/tools-1.png'
import react from '../../assets/images/tools-2.png'
import python from '../../assets/images/tools-3.png'
import java from '../../assets/images/tools-4.png'
import nodejs from '../../assets/images/tools-5.png'
import "../../css/AppDevelopment.css"
import ClientLogohead from '../Home/ClientLogohead'
import { FaAnglesRight } from "react-icons/fa6";
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { Link } from 'react-router-dom';
import ServiceContact from './ServiceContact'

const AppDevelopment = () => {
  return (
    <div>
        <div className='app-banner'>
            <img className='app-img' src={AppBanner} alt="" />
            <h1 className='app-banner-title'> Where Innovative App Solutions Meet
            Flawless Execution</h1>
        </div>
        <ClientLogohead/>
        <hr className='b-line' />
        <div className="mid-content">
          <div className="app-head-line">
          Why choose an App Development Service?
          </div>
          <p className="head-con">
          Choosing an app development service is not just about building an app; it's about partnering with a trusted advisor who shares your vision and is committed to your success. With our expertise, efficiency, and dedication to excellence, we're confident that we can help you achieve your app development goals and unlock the full potential of your business.
          </p>
          <div className="app-container-hl">
          Here’s what we offer!
          </div>
          <div className="app-container">
      <div className="app-image-section">
        <div className="app-image-box">
          <img src={ios} alt="Placeholder" className="img-fluid" />
        </div>
      </div>

      <div className="app-text-section">
        <h1>Custom IOS & Android App Development</h1>
        <p>
          We specialize in crafting bespoke mobile applications tailored to your
          specific needs and objectives. Whether you require a native iOS app for
          the Apple ecosystem, an Android app for the vast Android user base, or
          both, our experts have the experience and skills to bring your vision to life.
        </p>
        <Link to=''>
        <button className="app-read-more-btn">READ MORE <ArrowCircleRightIcon/></button>
        </Link>
      </div>
    </div>
          <div className="app-container">
      <div className="app-image-section">
        <div className="app-image-box">
          <img src={ios2} alt="Placeholder" className="img-fluid" />
        </div>
      </div>

      <div className="app-text-section">
        <h1>Cross-Platform App Development</h1>
        <p>
        Reach a wider audience and maximize your investment with cross-platform app development. Our developers leverage frameworks like React Native and Flutter to build high-performance apps that run seamlessly on multiple platforms, saving you time and resources without compromising on quality or user experience.</p>
        <Link to=''>
        <button className="app-read-more-btn">READ MORE <ArrowCircleRightIcon/></button>
        </Link>
      </div>
    </div>
          <div className="app-container">
      <div className="app-image-section">
        <div className="app-image-box">
          <img src={uiux} alt="Placeholder" className="img-fluid" />
        </div>
      </div>

      <div className="app-text-section">
        <h1>UI/UX Design</h1>
        <p>
        A captivating and intuitive user interface is essential for the success of any mobile app. Our UI/UX designers are masters at creating visually stunning interfaces and seamless navigation flows that enhance user engagement and drive conversions. From wireframing to prototyping to final design, we ensure that your app is well-optimized for usability and appeal.</p>
        <Link to=''>
        <button className="app-read-more-btn">READ MORE <ArrowCircleRightIcon/></button>
        </Link>
      </div>
    </div>
          <div className="app-container">
      <div className="app-image-section">
        <div className="app-image-box">
          <img src={consultion} alt="Placeholder" className="img-fluid" />
        </div>
      </div>

      <div className="app-text-section">
        <h1>Consulting & Prototyping</h1>
        <p>
        Transform your idea into a viable app with our consulting and prototyping services. Our experienced consultants will work closely with you to refine your concept, identify key features, and develop a roadmap for success.</p>
        <Link to=''>
        <button className="app-read-more-btn">READ MORE <ArrowCircleRightIcon/></button>
        </Link>
      </div>
    </div>
      <div className="app-container">
      <div className="app-image-section">
        <div className="app-image-box">
          <img src={support} alt="Placeholder" className="img-fluid" />
        </div>
      </div>

      <div className="app-text-section">
        <h1>Maintenance & Post-Warranty Support</h1>
        <p>
        Launching your app is just the beginning of the journey. We also provide comprehensive maintenance and post-warranty support services to ensure that your app remains stable, secure, and up-to-date long after its initial release. From bug fixes to performance optimizations or feature enhancements, we are here to support you every step of the way. both, our experts have the experience and skills to bring your vision to life.
        </p>
        <Link to=''>
        <button className="app-read-more-btn">READ MORE <ArrowCircleRightIcon/></button>
        </Link>
      </div>
    </div>
   </div>
   <div className="banner-2">
    <img className='banner-2-img' src={AppBanner2} alt="" />
    <div className="banner-2-text">
      <div className="b-2-h">Here’s how we do!</div>
      <div className="b-2-l">Our app development process begins with understanding your business goals and problems. Whether you have existing code or not, we've got you covered. Our team takes over existing code, if any, and works with it, or creates a fresh plan to build an app from scratch. <span>Let us help you take your business to the next level with a high-quality app that meets your needs.</span></div>
      <Link to=''>
        <button className="b-2-btn">LET'S CONNECT <ArrowCircleRightIcon/></button>
        </Link>
    </div>
   </div>
   <div className="stand">
    <div className="stand-h">
    What makes us stand apart?
    </div>
    <div className="stand-grid">
      <div className="expertise">
        <div className="expertise-h">
        <div className="stand-icon"><FaAnglesRight /></div>
        Expertise
      </div>
      <div className="expertise-con">
      Our team comprises seasoned developers, strategists, and designers with great expertise in their respective fields. We aim to bring years of collective experience and a passion for innovation to every project.
      </div>
      </div>
      <div className="expertise">
        <div className="expertise-h">
        <div className="stand-icon"><FaAnglesRight /></div>
        Tailored Solutions
      </div>
      <div className="expertise-con">
      We understand that every project is unique. That's why we take the time to listen to your requirements, analyze its goals, and craft custom solutions that align perfectly with your vision.      </div>
      </div>
      <div className="expertise">
        <div className="expertise-h">
        <div className="stand-icon"><FaAnglesRight /></div>
        Cutting-Edge Technology
      </div>
      <div className="expertise-con">
      We stay ahead of the curve by leveraging the latest technologies and frameworks. From native iOS and Android development to cross-platform solutions, we have the expertise to bring your app to life on any platform.      </div>
      </div>
      <div className="expertise">
        <div className="expertise-h">
        <div className="stand-icon"><FaAnglesRight /></div>
        Agile Development Process
      </div>
      <div className="expertise-con">
      Our agile development methodology allows us to adapt quickly to changing requirements and deliver results efficiently. We keep you involved at every step/stage of the process, ensuring utmost transparency.
      </div>
      </div>
      </div>
      <div className="expertise" id='expertise-last' >
        <div className="expertise-h">
          <div className="stand-icon"><FaAnglesRight /></div>
        Client-Centric Approach
      </div>
      <div className="expertise-con">
      At RIO, your satisfaction is our top priority. We prioritize a collaborative approach, responsiveness, and open communication, to ensure that your vision is brought to life exactly as you envision it. Your success is our success.
      </div>
      </div>
      <div className="tool-con" >
         Tools & Technologies
        <div className="tool-logo">
          <img src={flutter} alt="" />
          <img src={react} alt="" />
          <img src={python} alt="" />
          <img src={java} alt="" />
          <img src={nodejs} alt="" />
        </div>
      </div>
      {/* <div className="contact-con">
        <div className="contact-h">
        Let's Discuss Your Project
        </div>
        <div className="contact-p">
        Contact us today to discuss your project requirements and discover how we can help you achieve your goals. Together, we create an app that stands out in the crowded digital landscape and delivers exceptional value to your users.
        </div>
      </div> */}
      <ServiceContact/>
   </div>
  </div>
  )
}

export default AppDevelopment